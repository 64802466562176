
/**
 * @roxi/routify 2.2.2
 * File generated Sat Apr 08 2023 11:10:17 GMT+0200 (heure d’été d’Europe centrale)
 */

export const __version = "2.2.2"
export const __timestamp = "2023-04-08T09:10:17.685Z"

//buildRoutes
import { buildClientTree } from "@roxi/routify/runtime/buildRoutes"

//imports


//options
export const options = {}

//tree
export const _tree = {
  "name": "_folder",
  "filepath": "/_folder.svelte",
  "root": true,
  "ownMeta": {},
  "absolutePath": "C:/Users/Admin/Desktop/Boltaction/src/pages/_folder.svelte",
  "children": [
    {
      "isFile": true,
      "isDir": false,
      "ext": "svelte",
      "isLayout": false,
      "isReset": false,
      "isIndex": false,
      "isFallback": true,
      "isPage": false,
      "ownMeta": {},
      "meta": {
        "recursive": true,
        "preload": false,
        "prerender": true
      },
      "path": "/_fallback",
      "id": "__fallback",
      "component": () => import('../src/pages/_fallback.svelte').then(m => m.default)
    },
    {
      "isFile": true,
      "isDir": false,
      "ext": "svelte",
      "isLayout": false,
      "isReset": false,
      "isIndex": false,
      "isFallback": false,
      "isPage": true,
      "ownMeta": {},
      "meta": {
        "recursive": true,
        "preload": false,
        "prerender": true
      },
      "path": "/analyzer",
      "id": "_analyzer",
      "component": () => import('../src/pages/analyzer.svelte').then(m => m.default)
    },
    {
      "isFile": true,
      "isDir": true,
      "ext": "svelte",
      "children": [
        {
          "isFile": true,
          "isDir": false,
          "ext": "svelte",
          "isLayout": false,
          "isReset": false,
          "isIndex": false,
          "isFallback": false,
          "isPage": true,
          "ownMeta": {},
          "meta": {
            "recursive": true,
            "preload": false,
            "prerender": true
          },
          "path": "/missions/Bravoure-insolente",
          "id": "_missions_BravoureInsolente",
          "component": () => import('../src/pages/missions/Bravoure-insolente.svelte').then(m => m.default)
        },
        {
          "isFile": true,
          "isDir": false,
          "ext": "svelte",
          "isLayout": false,
          "isReset": false,
          "isIndex": false,
          "isFallback": false,
          "isPage": true,
          "ownMeta": {},
          "meta": {
            "recursive": true,
            "preload": false,
            "prerender": true
          },
          "path": "/missions/Choc",
          "id": "_missions_Choc",
          "component": () => import('../src/pages/missions/Choc.svelte').then(m => m.default)
        },
        {
          "isFile": true,
          "isDir": false,
          "ext": "svelte",
          "isLayout": false,
          "isReset": false,
          "isIndex": false,
          "isFallback": false,
          "isPage": true,
          "ownMeta": {},
          "meta": {
            "recursive": true,
            "preload": false,
            "prerender": true
          },
          "path": "/missions/Controlez-le-flanc",
          "id": "_missions_ControlezLeFlanc",
          "component": () => import('../src/pages/missions/Controlez-le-flanc.svelte').then(m => m.default)
        },
        {
          "isFile": true,
          "isDir": false,
          "ext": "svelte",
          "isLayout": false,
          "isReset": false,
          "isIndex": false,
          "isFallback": false,
          "isPage": true,
          "ownMeta": {},
          "meta": {
            "recursive": true,
            "preload": false,
            "prerender": true
          },
          "path": "/missions/Decapitation",
          "id": "_missions_Decapitation",
          "component": () => import('../src/pages/missions/Decapitation.svelte').then(m => m.default)
        },
        {
          "isFile": true,
          "isDir": false,
          "ext": "svelte",
          "isLayout": false,
          "isReset": false,
          "isIndex": false,
          "isFallback": false,
          "isPage": true,
          "ownMeta": {},
          "meta": {
            "recursive": true,
            "preload": false,
            "prerender": true
          },
          "path": "/missions/Enveloppement",
          "id": "_missions_Enveloppement",
          "component": () => import('../src/pages/missions/Enveloppement.svelte').then(m => m.default)
        },
        {
          "isFile": true,
          "isDir": false,
          "ext": "svelte",
          "isLayout": false,
          "isReset": false,
          "isIndex": false,
          "isFallback": false,
          "isPage": true,
          "ownMeta": {},
          "meta": {
            "recursive": true,
            "preload": false,
            "prerender": true
          },
          "path": "/missions/Faites-des-prisonniers",
          "id": "_missions_FaitesDesPrisonniers",
          "component": () => import('../src/pages/missions/Faites-des-prisonniers.svelte').then(m => m.default)
        },
        {
          "isFile": true,
          "isDir": false,
          "ext": "svelte",
          "isLayout": false,
          "isReset": false,
          "isIndex": false,
          "isFallback": false,
          "isPage": true,
          "ownMeta": {},
          "meta": {
            "recursive": true,
            "preload": false,
            "prerender": true
          },
          "path": "/missions/Gardez-les-sous-pression",
          "id": "_missions_GardezLesSousPression",
          "component": () => import('../src/pages/missions/Gardez-les-sous-pression.svelte').then(m => m.default)
        },
        {
          "isFile": true,
          "isDir": false,
          "ext": "svelte",
          "isLayout": false,
          "isReset": false,
          "isIndex": false,
          "isFallback": false,
          "isPage": true,
          "ownMeta": {},
          "meta": {
            "recursive": true,
            "preload": false,
            "prerender": true
          },
          "path": "/missions/La-Taupe",
          "id": "_missions_LaTaupe",
          "component": () => import('../src/pages/missions/La-Taupe.svelte').then(m => m.default)
        },
        {
          "isFile": true,
          "isDir": false,
          "ext": "svelte",
          "isLayout": false,
          "isReset": false,
          "isIndex": false,
          "isFallback": false,
          "isPage": true,
          "ownMeta": {},
          "meta": {
            "recursive": true,
            "preload": false,
            "prerender": true
          },
          "path": "/missions/Panne-seche",
          "id": "_missions_PanneSeche",
          "component": () => import('../src/pages/missions/Panne-seche.svelte').then(m => m.default)
        },
        {
          "isFile": true,
          "isDir": false,
          "ext": "svelte",
          "isLayout": false,
          "isReset": false,
          "isIndex": false,
          "isFallback": false,
          "isPage": true,
          "ownMeta": {},
          "meta": {
            "recursive": true,
            "preload": false,
            "prerender": true
          },
          "path": "/missions/Sonder-les-defenses",
          "id": "_missions_SonderLesDefenses",
          "component": () => import('../src/pages/missions/Sonder-les-defenses.svelte').then(m => m.default)
        },
        {
          "isFile": true,
          "isDir": false,
          "ext": "svelte",
          "isLayout": false,
          "isReset": false,
          "isIndex": false,
          "isFallback": false,
          "isPage": true,
          "ownMeta": {},
          "meta": {
            "recursive": true,
            "preload": false,
            "prerender": true
          },
          "path": "/missions/Tenez-bon",
          "id": "_missions_TenezBon",
          "component": () => import('../src/pages/missions/Tenez-bon.svelte').then(m => m.default)
        }
      ],
      "isLayout": true,
      "isReset": false,
      "isIndex": false,
      "isFallback": false,
      "isPage": false,
      "ownMeta": {},
      "meta": {
        "recursive": true,
        "preload": false,
        "prerender": true
      },
      "path": "/missions",
      "id": "_missions__layout",
      "component": () => import('../src/pages/missions/_layout.svelte').then(m => m.default)
    },
    {
      "isFile": true,
      "isDir": false,
      "ext": "svelte",
      "isLayout": false,
      "isReset": false,
      "isIndex": false,
      "isFallback": false,
      "isPage": true,
      "ownMeta": {
        "title": "About"
      },
      "meta": {
        "title": "About",
        "recursive": true,
        "preload": false,
        "prerender": true
      },
      "path": "/about",
      "id": "_about",
      "component": () => import('../src/pages/about.svelte').then(m => m.default)
    },
    {
      "isFile": true,
      "isDir": false,
      "ext": "svelte",
      "isLayout": false,
      "isReset": false,
      "isIndex": false,
      "isFallback": false,
      "isPage": true,
      "ownMeta": {
        "title": "Conditions"
      },
      "meta": {
        "title": "Conditions",
        "recursive": true,
        "preload": false,
        "prerender": true
      },
      "path": "/conditions",
      "id": "_conditions",
      "component": () => import('../src/pages/conditions.svelte').then(m => m.default)
    },
    {
      "isFile": true,
      "isDir": false,
      "ext": "svelte",
      "isLayout": false,
      "isReset": false,
      "isIndex": false,
      "isFallback": false,
      "isPage": true,
      "ownMeta": {
        "title": "Feedback"
      },
      "meta": {
        "title": "Feedback",
        "recursive": true,
        "preload": false,
        "prerender": true
      },
      "path": "/feedback",
      "id": "_feedback",
      "component": () => import('../src/pages/feedback.svelte').then(m => m.default)
    },
    {
      "isFile": true,
      "isDir": false,
      "ext": "svelte",
      "isLayout": false,
      "isReset": false,
      "isIndex": true,
      "isFallback": false,
      "isPage": true,
      "ownMeta": {
        "title": "Generator"
      },
      "meta": {
        "title": "Generator",
        "recursive": true,
        "preload": false,
        "prerender": true
      },
      "path": "/index",
      "id": "_index",
      "component": () => import('../src/pages/index.svelte').then(m => m.default)
    },
    {
      "isFile": true,
      "isDir": false,
      "ext": "svelte",
      "isLayout": false,
      "isReset": false,
      "isIndex": false,
      "isFallback": false,
      "isPage": true,
      "ownMeta": {
        "title": "Missions"
      },
      "meta": {
        "title": "Missions",
        "recursive": true,
        "preload": false,
        "prerender": true
      },
      "path": "/missions",
      "id": "_missions",
      "component": () => import('../src/pages/missions.svelte').then(m => m.default)
    }
  ],
  "isLayout": true,
  "isReset": false,
  "isIndex": false,
  "isFallback": false,
  "isPage": false,
  "isFile": true,
  "file": "_folder.svelte",
  "ext": "svelte",
  "badExt": false,
  "importPath": "../src/pages/_folder.svelte",
  "meta": {
    "recursive": true,
    "preload": false,
    "prerender": true
  },
  "path": "/",
  "id": "__folder",
  "component": () => import('../src/pages/_folder.svelte').then(m => m.default)
}


export const {tree, routes} = buildClientTree(_tree)

